<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <commitment-detail
        :type="type"
      />
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  export default {
    name: 'ApplicationDashboard',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      CommitmentDetail: () => import('./components/CommitmentDetails'),
    },
    props: {
      type: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/',
          },
          {
            text: this.$t('bcDashboard'),
            disabled: false,
            href: '/dashboard',
          },
        ],
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
      appTitle: function () {
        let title = ''
        if (this.currentUser != null) {
          switch (Number(this.type)) {
            case 1:
              title = this.currentUser.partnerName + ' - ' + this.$t('titleTakebackDetails-Purchase')
              break
            case 2:
              title = this.currentUser.partnerName + ' - ' + this.$t('titleTakebackDetails-Sales')
              break
          }
        } else {
          title = this.$t('titleTakebackDetails-Purchase')
        }
        return title
      },
    },
    methods: {
    },
  }
</script>
